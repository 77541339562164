import { combineReducers } from '@reduxjs/toolkit';
import dashboardReducer from './Dashboard/dashboardSlice';
import trackerReducer from './Tracker/trackerSlice';
import overTimeReducer from './OverTimeList/overTimeSlice';
import InVoiceReducer from './Invoice/InvoiceSlice';
import memberShipSlice from './MemberShip/memberShipSlice';
import userReducer from './userSlice';
import notificationReducer from './Notification/userNotificationsSlice';
import checkListReducer from './CheckList/checklistSlice';
import assignedDocumentReducer from './AssignedDocument/assignedDocumentSlice';
import documentReducer from './Product/documentSlice';


const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  timeTracker: trackerReducer,
  overTime: overTimeReducer,
  invoice: InVoiceReducer,
  user: userReducer,
  membership: memberShipSlice,
  notifications: notificationReducer,
  checklistitems: checkListReducer,
  documents: documentReducer,
  assignedDocuments: assignedDocumentReducer,
});

export default rootReducer;
