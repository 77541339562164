import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Badge,
  Divider,
  Typography,
  Avatar,
  styled,
} from "@mui/material";
import {
  MdDashboard,
  MdPeople,
  MdWork,
  MdAccessTime,
  MdCalendarToday,
  MdReceipt,
  MdAssessment,
  MdOutlineAccountBalance,
  MdSettings,
  MdExpandMore,
  MdExpandLess,
  MdChevronRight,
  MdCheckBox,
  MdManageHistory,
  MdWorkOff,
  MdTimer,
} from "react-icons/md";
import { TbReportAnalytics, TbBriefcase } from "react-icons/tb";
import { getUnreadCount } from "../store/Notification/userNotificationsSlice";

// Components
import Header from "../common/Header";
import Home from "./Home";
import Staff from "./Staff";
import Profile from "./Profile";
import Jobs from "./Jobs";
import EmpDetails from "../view/EmpDetails";
import Calendar from "./Calendar";
import ExportAllEmployeesData from "./ExportAllEmployeesData";
import State from "./State";
import NotificationList from "./NotificationList";
import TimeTracker from "./timeTracker";
import CheckList from "./checkList";
import TimeTrackerRequest from "./TimeTrackerRequest";
import AddLeave from "./Leaves/AddLeave";
import EmployeeLeaveList from "./Leaves/EmployeeLeaveList";
import LeaveTypeManagement from "./Leaves/LeaveTypeManagement";
import LeaveRequest from "./LeaveRequest";
import Holidays from "./Holidays";
import ShiftSchedule from "./ShiftSchedule";
import Anlaytics from "./Anlaytics";
import Invoice from "./Invoice";
import InvoiceListing from "./InvoiceListing";
import VendorDashboard from "../components/VendorDashboard";
import Shift from "./Shift";
import ShiftAssign from "../view/ShiftAssign";

const drawerWidth = 280;

// Styled components
const StyledListItemButton = styled(ListItemButton)(({ theme, active }) => ({
  borderRadius: "8px",
  backgroundColor: active ? "#108a00" : "transparent",
  color: active ? "white" : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: active ? "#108a00" : theme.palette.action.hover,
  },
  my: 0.5,
  transition: "all 0.2s ease-in-out",
}));

const StyledListItemIcon = styled(ListItemIcon)(({ active }) => ({
  minWidth: "40px",
  color: active ? "white" : "inherit",
}));

// Menu Categories Configuration
const menuCategories = [
  {
    title: "Dashboard",
    icon: MdDashboard,
    path: "/",
    active: 0,
  },
  {
    title: "Management",
    icon: MdPeople,
    items: [
      {
        title: "Employees",
        path: "/staff",
        active: 2,
        icon: MdPeople,
      },
      {
        title: "Jobs",
        path: "/job",
        active: 1,
        icon: MdWork,
      },
      {
        title: "Shift Master",
        path: "/shift",
        active: 7,
        icon: MdManageHistory,
      },
      {
        title: "Shift Schedule",
        path: "/shift-schedule",
        active: 21,
        icon: MdCalendarToday,
      },
    ],
  },
  {
    title: "Time & Attendance",
    icon: MdAccessTime,
    items: [
      {
        title: "Tracker Management",
        path: "/timeTracker",
        active: 10,
        icon: MdAccessTime,
      },
      {
        title: "Tracker Requests",
        path: "/tracker-approvals",
        active: 18,
        icon: MdTimer,
      },
      {
        title: "Check-List",
        path: "/checkList",
        active: 11,
        icon: MdCheckBox,
      },
      {
        title: "OT Calculations",
        path: "/state",
        active: 8,
        icon: TbBriefcase,
      },
    ],
  },
  {
    title: "Leave Management",
    icon: MdCalendarToday,
    items: [
      {
        title: "Leave Types",
        path: "/leave-management/types",
        active: 20,
        icon: MdWorkOff,
      },
      {
        title: "Leave Management",
        path: "/leave-management",
        active: 19,
        icon: MdSettings,
      },
      {
        title: "Leave Requests",
        path: "/leave-request",
        active: 23,
        icon: MdCalendarToday,
      },
      {
        title: "Holidays",
        path: "/holidays",
        active: 24,
        icon: MdCalendarToday,
      },
    ],
  },
  {
    title: "Documents",
    icon: MdReceipt,
    path: "/documents",
    active: 31,
  },
  {
    title: "Reports & Analytics",
    icon: MdAssessment,
    items: [
      {
        title: "Analytics",
        path: "/analytics",
        active: 27,
        icon: MdAssessment,
      },
      {
        title: "Reports",
        path: "/report",
        active: 5,
        icon: TbReportAnalytics,
      },
    ],
  },
  {
    title: "Financials",
    icon: MdOutlineAccountBalance,
    items: [
      {
        title: "Invoices",
        path: "/invoice",
        active: 30,
        icon: MdOutlineAccountBalance,
      },
    ],
  },
  {
    title: "Account",
    icon: MdSettings,
    path: "/account",
    active: 3,
  },
];

const MenuItem = ({ item, active, setActive, level = 0 }) => {
  const [open, setOpen] = useState(false);
  const notificationCount = useSelector(
    (state) => state.notifications.unreadCount
  );
  const hasItems = item.items && item.items.length > 0;
  const isActive = active === item.active;

  const handleClick = () => {
    if (hasItems) {
      setOpen(!open);
    } else {
      setActive(item.active);
    }
  };

  return (
    <>
      <ListItem disablePadding sx={{ pl: level * 2 }}>
        <StyledListItemButton
          onClick={handleClick}
          active={isActive ? 1 : 0}
          component={hasItems ? "div" : Link}
          to={hasItems ? null : item.path}
        >
          <StyledListItemIcon active={isActive ? 1 : 0}>
            <item.icon size={20} />
          </StyledListItemIcon>
          <ListItemText
            primary={item.title}
            primaryTypographyProps={{ fontWeight: isActive ? "600" : "normal" }}
          />
          {hasItems ? (
            open ? (
              <MdExpandLess size={20} />
            ) : (
              <MdChevronRight size={20} />
            )
          ) : item.path === "/notifications" && notificationCount > 0 ? (
            <Badge badgeContent={notificationCount} color="error" />
          ) : null}
        </StyledListItemButton>
      </ListItem>
      {hasItems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.items.map((subItem) => (
              <MenuItem
                key={subItem.path}
                item={subItem}
                active={active}
                setActive={setActive}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

function Dashboard({ user }) {
  const [activeMenu, setActiveMenu] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getUnreadCount(user?._id));
  }, [user, location.pathname, dispatch]);

  return (
    <Box
      sx={{ display: "flex", height: "100vh",  borderRight: '1px solid rgba(0, 0, 0, 0.12)', }}
    >
      <CssBaseline />
      <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)", // Add border here
          backgroundColor: "#f9f9f9",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          height: 120,
          backgroundColor: "white",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <img
          src="./logo/sky.png"
          alt="logo"
          style={{
            width: "160px",
            height: "auto",
            objectFit: "contain",
          }}
        />
      </Box>

        {/* User Profile Section */}
        {/* <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            gap: 2,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <Avatar
            src={user?.profileImage}
            alt={user?.name}
            sx={{ width: 48, height: 48 }}
          />
          <Box>
            <Typography variant="subtitle1" fontWeight="medium">
              {user?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {user?.role}
            </Typography>
          </Box>
        </Box> */}

        {/* Menu Section */}
        <Box sx={{ p: 2, overflowY: "auto", flexGrow: 1 }}>
          <List>
            {menuCategories.map((category) => (
              <React.Fragment key={category.title}>
                {category.items ? (
                  <MenuItem
                    item={category}
                    active={activeMenu}
                    setActive={setActiveMenu}
                  />
                ) : (
                  <Tooltip title={category.title} placement="right">
                    <ListItem disablePadding>
                      <StyledListItemButton
                        component={Link}
                        to={category.path}
                        active={activeMenu === category.active ? 1 : 0}
                        onClick={() => setActiveMenu(category.active)}
                      >
                        <StyledListItemIcon
                          active={activeMenu === category.active ? 1 : 0}
                        >
                          <category.icon size={20} />
                        </StyledListItemIcon>
                        <ListItemText
                          primary={category.title}
                          primaryTypographyProps={{
                            fontWeight:
                              activeMenu === category.active ? "600" : "normal",
                          }}
                        />
                      </StyledListItemButton>
                    </ListItem>
                  </Tooltip>
                )}
                <Divider sx={{ my: 1 }} />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          height: "100vh",
          overflowX: "hidden", // Prevent horizontal scrolling
          overflowY: "auto", // Allow only vertical scrolling
          maxWidth: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        {activeMenu !== 3 && (
          <Header
            title={
              menuCategories.find((m) => m.active === activeMenu)?.title ||
              menuCategories
                .flatMap((c) => c.items || [])
                .find((i) => i.active === activeMenu)?.title ||
              "Dashboard"
            }
          />
        )}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/analytics" element={<Anlaytics />} />
          <Route path="/job" element={<Jobs user={user} />} />
          <Route path="/shift" element={<Shift user={user} />} />
          <Route
            path="/tracker-approvals"
            element={<TimeTrackerRequest user={user} />}
          />
          <Route
            path="/shift/assign/:id"
            element={<ShiftAssign user={user} />}
          />
          <Route path="/state" element={<State user={user} />} />
          <Route path="/staff" element={<Staff user={user} />} />
          <Route path="/staff/:id" element={<EmpDetails user={user} />} />
          <Route path="/account" element={<Profile user={user} />} />
          <Route
            path="/notifications"
            element={<NotificationList user={user} />}
          />
          <Route path="/calendar" element={<Calendar user={user} />} />
          <Route path="/timeTracker" element={<TimeTracker user={user} />} />
          <Route path="/checkList" element={<CheckList user={user} />} />
          <Route
            path="/leave-management/types"
            element={<LeaveTypeManagement user={user} />}
          />
          <Route path="/holidays" element={<Holidays user={user} />} />
          <Route path="/leave-request" element={<LeaveRequest user={user} />} />
          <Route
            path="/leave-management"
            element={<EmployeeLeaveList user={user} />}
          />
          <Route
            path="/leave-management/add"
            element={<AddLeave user={user} />}
          />
          <Route
            path="/shift-schedule"
            element={<ShiftSchedule user={user} />}
          />
          <Route path="/send-invoice" element={<Invoice user={user} />} />
          <Route path="/invoice" element={<InvoiceListing user={user} />} />
          <Route path="/documents" element={<VendorDashboard user={user} />} />
          <Route
            path="/report"
            element={<ExportAllEmployeesData user={user} />}
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;
