import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";

import { Grid, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import Modal from "@mui/material/Modal";
import { CSVLink } from "react-csv";
import {
  fetchEmployeeAssignedJobs,
  fetchEmployeesByVendor,
  loadEmployeeTracker,
  submitTimeTracker,
} from "../store/Tracker/trackerSlice";
import {
  convertToUTC,
  formatDateTime,
  formatTime,
  formatToSimpleDate,
  getUserName,
  processOverTimeData,
} from "../Helper/functions";
import { FaEdit } from "react-icons/fa";
import moment from "moment";

const TimeTracker = ({user}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [employee, setEmployee] = useState("");
  const [employeeJob, setEmployeeJob] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedJob, setSelectedJob] = useState(null);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { JobsTracking, employees, assignedJobsToEmployee } = useSelector(
    (state) => state.timeTracker
  );
  const handlePopupSubmit = (e) => {
    e.preventDefault();
    dispatch(
      submitTimeTracker({
        jobId: selectedJob.jobId,
        startTime :convertToUTC( selectedJob.sessionDate,startTime),
        endTime :convertToUTC( selectedJob.sessionDate,endTime),
        date: selectedJob.sessionDate,
      })
    )
      .unwrap()
      .then(() => {
        setShowModal(false);
        dispatch(
          loadEmployeeTracker({ employee, employeeJob, startDate, endDate })
        );
      })
      .catch((error) => {
        console.error("Failed to submit time data:", error);
      });
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchEmployeesByVendor(user?._id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (employee) {
      dispatch(fetchEmployeeAssignedJobs({ vendor: user?._id, employee }));
    }
  }, [employee, dispatch]);

  useEffect(() => {
    let newData = processOverTimeData(JobsTracking);
    setData(newData);
  }, [JobsTracking]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      loadEmployeeTracker({ employee, employeeJob, startDate, endDate })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ borderRadius: 0, width: "100%", ml: 1, p: 2 }}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} className="p-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <label>Employee</label>
                    <Select
                      value={employee}
                      onChange={(e) => setEmployee(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {employees?.map((employee) => (
                        <MenuItem key={employee._id} value={employee._id}>
                          {getUserName(employee)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={2}>
                  <FormControl fullWidth>
                    <label>Select Job</label>
                    <Select
                      value={employeeJob}
                      onChange={(e) => setEmployeeJob(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {assignedJobsToEmployee?.map((job) => (
                        <MenuItem key={job._id} value={job._id}>
                          {job.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={2}>
                    <label htmlFor="">Start Date</label>
                  <TextField
                    
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                <label htmlFor="">End Date</label>

                  <TextField
                
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={3} className="text-center" style={{display:'flex',alignItems:'flex-end'}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ height: 40, marginRight: "10px" }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEmployee("");
                      setEmployeeJob("");
                      setStartDate("");
                      setEndDate("");
                    }}
                    sx={{ height: 40, marginRight: "10px" }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper sx={{ borderRadius: 0, width: "100%", ml: 1, p: 2 }}>
        <Box>
          {/* Time Tracker Information Section */}
          <Grid container spacing={2}>
            <div className="MuiBox-root css-hoht9f " style={{justifyContent:'right',width:'100%',display:'flex',marginTop:'20px'}}>
              <div className="MuiBox-root css-i9gxme"></div>
              <CSVLink data={data} filename="data.csv" className="csvButton">
                Export to CSV
              </CSVLink>
            </div>

            {/* Table */}
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table /* aria-label='collapsible table' */>
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#E4EAEE", color: "white" }}>
                      <TableCell sx={{ color: "black" }}>Job Name</TableCell>
                      <TableCell sx={{ color: "black" }} align="center">
                        Total Amount
                      </TableCell>
                      <TableCell sx={{ color: "black" }} align="center">
                        Date
                      </TableCell>
                      <TableCell sx={{ color: "black" }} align="center">
                        Start Time
                      </TableCell>
                      <TableCell sx={{ color: "black" }} align="center">
                        End Time
                      </TableCell>
                      <TableCell sx={{ color: "black" }} align="center">
                        Break Time
                      </TableCell>
                      <TableCell sx={{ color: "black" }} align="center">
                        Total Time
                      </TableCell>
                      <TableCell sx={{ color: "black" }} align="center">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {JobsTracking && JobsTracking.length ? (
                      JobsTracking.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).map((task, ind) => {
                        return (
                          <TableRow key={ind} hover>
                            <TableCell>{task.jobDetails.name}</TableCell>
                            <TableCell align="center">
                              ${task?.amount ?? "0:00"}
                            </TableCell>
                            <TableCell align="center">
                              {formatToSimpleDate(task?.startTime)}{" "}
                            </TableCell>

                            <TableCell align="center">
                              {formatDateTime(task?.startTime)}{" "}
                            </TableCell>
                            <TableCell align="center">
                              {task?.stoppedTime
                                ? formatDateTime(task?.stoppedTime)
                                : "00:00:00"}{" "}
                            </TableCell>
                            <TableCell align="center">
                              {formatTime(task.totalBreakTime)}
                            </TableCell>
                            <TableCell align="center">
                              {formatTime(task.elapsedTime)}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title=" Update Timer">
                                <IconButton
                                  color="warning"
                                  onClick={() => {
                                    setSelectedJob(task);
                                    setShowModal(true);
                                  }}
                                >
                                  {" "}
                                  <FaEdit />{" "}
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography>No data found!</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={JobsTracking?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>

            {/* Modal for entering start and end time */}
            {selectedJob && (
              <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="edit-time-dialog-title"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle id="edit-time-dialog-title">
                  Edit Time Detail
                </DialogTitle>
                <form onSubmit={handlePopupSubmit}>
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <label htmlFor="">Start Time</label>
                        <TextField
                          type="time"
                          fullWidth
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                      <label htmlFor="">End Time</label>

                        <TextField
                          type="time"
                          fullWidth
                          value={endTime}
                          onChange={(e) => setEndTime(e.target.value)}
                          required
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            )}
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default TimeTracker;
